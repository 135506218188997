import decode from 'jwt-decode';
import { DateTime } from 'luxon';
import { AsyncSubject, BehaviorSubject } from 'rxjs';
import { concatMapTo } from 'rxjs/operators';

import { auth } from './network';
import { AuthDetails, AuthSignInResponse, User } from './types';

const TOKENS_KEY = 'gs_user_tokens';

type Token = string;
type Tokens = AuthSignInResponse;

interface TokenClaims {
  token_type: 'access' | 'refresh';
  exp: number;
  jti: string;
  user_id: number;
}

class AuthService {
  public tokens$ = new BehaviorSubject<Tokens | undefined>(undefined);
  public user$ = new BehaviorSubject<Partial<User> | undefined>(undefined);
  public stable$ = new AsyncSubject<void>();

  constructor() {
    this.initSubscriptions();
    this.initAuth();
  }

  async signIn(details: AuthDetails) {
    const tokens = await auth.signIn(details);
    this.update(tokens);
  }

  async signOut() {
    this.update();
  }

  get header(): string | undefined {
    const tokens = this.tokens$.value;
    return tokens ? `Bearer ${tokens.access}` : undefined;
  }

  // Constructor utilities

  private async initSubscriptions() {
    // Storage subscription
    this.stable$.pipe(concatMapTo(this.tokens$)).subscribe(tokens => {
      if (tokens) this.store(tokens);
      else this.remove();
    });

    // User subscription
    // TODO IMPROVE get network user details
    this.tokens$.subscribe(tokens => {
      const user = tokens
        ? { id: this.decode(tokens.access).user_id }
        : undefined;
      this.user$.next(user);
    });
  }

  private async initAuth() {
    await this.hydrate();
    this.stabilize();
  }

  private async hydrate() {
    const tokens = this.retrieve();
    if (!tokens) return;

    const { refresh } = tokens;
    if (this.expired(refresh)) return;

    // TODO EXTEND THIS LINE DESTRUCTURING IF WE WANT TO ALSO REFRESH THE REFRESH TOKEN
    const { access } = await auth.refresh(refresh);
    const newTokens = { access, refresh };

    this.update(newTokens);
  }

  // Observable primitives

  private update(tokens?: Tokens) {
    this.tokens$.next(tokens);
  }

  private stabilize() {
    this.stable$.next();
    this.stable$.complete();
  }

  // Token / LocalStorage primitives

  private decode(token: Token): TokenClaims {
    return decode(token);
  }

  private expired(token: Token): boolean {
    return DateTime.fromSeconds(this.decode(token).exp) <= DateTime.local();
  }

  private store(tokens: Tokens) {
    localStorage.setItem(TOKENS_KEY, JSON.stringify(tokens));
  }

  private retrieve(): Tokens | undefined {
    const item = localStorage.getItem(TOKENS_KEY);
    return (item && JSON.parse(item)) || undefined;
  }

  private remove() {
    localStorage.removeItem(TOKENS_KEY);
  }
}

export default new AuthService();
