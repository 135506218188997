import { tap } from 'rxjs/operators';
import { AxiosError } from 'axios';
import { get, has, isEmpty } from 'lodash';

import notSvc from './notification.service';

// Observable debug utility
export const debug = <T>(tag: string) => {
  return tap<T>(
    value => console.log(`${tag}: ${value}`),
    error => console.error(`${tag}: ${error}`),
    () => console.log(`${tag} [completed]`),
  );
};

export const catchNotify = (e: any) => {
  // Unknown
  let message = 'Unknown Error';

  // Axios network error
  if (has(e, 'response')) {
    const error = e as AxiosError;
    const response = error.response!;

    message = has(response, 'data.detail')
      ? `${get(response, 'data.detail')}`
      : `[${response.status}] ${response.statusText}`;
  }

  // Error with message
  else if (has(e, 'message') && !isEmpty(message)) {
    message = e.message;
  }

  notSvc.notify({ type: 'error', message });
};
