import { find } from 'lodash';

import axios from './axios';
import { AuthDetails, AuthSignInResponse, Domain } from './types';

const FAKE_DOMAINS: Domain[] = [
  { id: 1, name: 'google.com', approved: true },
  { id: 2, name: 'facebook.com', approved: false },
];

export const auth = {
  async signIn(details: AuthDetails): Promise<AuthSignInResponse> {
    const response = await axios.post<AuthSignInResponse>('/api/token/', {
      username: details.email,
      password: details.password,
    });

    return response.data;
  },

  async refresh(refreshToken: string) {
    const response = await axios.post<AuthSignInResponse>(
      '/api/token/refresh/',
      { refresh: refreshToken },
    );

    return response.data;
  },

  // FAKE CALLS

  async fakeSignIn(details: AuthDetails): Promise<AuthSignInResponse> {
    // Email: manuelalejandropm@gmail.com
    // exp: 1560000000 (June 2019)
    const token =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1hbnVlbEBsZXRzdGFsa21lZGlhLmNvbSIsImV4cCI6MTU2MDAwMDAwMH0.YjYYwRcdoh_PBQVwziVrz5POsP5k9Zn51I18jWn7qjs';

    return { access: token, refresh: token };
  },
};

export const domains = {
  async getAll() {
    const response = await axios.get('/domain/');
    return response.data;
  },

  async getOne(id: number): Promise<Domain | undefined> {
    const response = await axios.get(`/domain/${id}/`);
    return response.data;
  },

  async post(domain: Partial<Domain>) {
    const response = await axios.post('/domain/', { name: domain.name });
    return response.data;
  },

  // FAKE CALLS

  async fakeGetAll(): Promise<Domain[]> {
    return FAKE_DOMAINS;
  },

  async fakeGetOne(name: string): Promise<Domain | undefined> {
    return find(FAKE_DOMAINS, d => d.name === name);
  },

  async fakePost(domain: Domain) {
    const id = FAKE_DOMAINS.length + 1;
    FAKE_DOMAINS.push({ ...domain, id, approved: false });
    return domain;
  },
};
