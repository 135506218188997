import React from 'react';
import { Link, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import { StreamBuilder } from 'react-stream-builder';

import authSvc from './core/auth.service';
import { withAuthGuard } from './shared/guards';
import Notifications from './shared/notifications';

import Auth from './components/auth';
import DomainIndex from './components/domain-index';
import DomainNew from './components/domain-new';
import DomainShow from './components/domain-show';
import Home from './components/home';

const useStyles = makeStyles({
  root: {
    margin: '0 auto',
    padding: 30,
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: 700,
  },
});

const App = () => {
  const classes = useStyles();

  return (
    <Router>
      <div className={classes.root}>
        {/* Navigation */}
        <nav>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/auth">Auth</Link>
          </li>
          <li>
            <Link to="/domains">Domains</Link>
          </li>
        </nav>

        {/* User Details */}
        <StreamBuilder
          stream={authSvc.user$}
          builder={({ data: user }) => {
            if (!user) return null;

            return (
              <div>
                User email: {user.email}
                <button type="button" onClick={() => authSvc.signOut()}>
                  Sign out
                </button>
              </div>
            );
          }}
        />

        {/* Notifications */}
        <Notifications />

        {/* Routes */}
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/auth" exact component={Auth} />
          <Switch>
            <Route
              path="/domains"
              exact
              component={withAuthGuard(DomainIndex)}
            />
            <Route
              path="/domains/new"
              exact
              component={withAuthGuard(DomainNew)}
            />
            <Route
              path="/domains/:id"
              exact
              component={withAuthGuard(DomainShow)}
            />
          </Switch>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
