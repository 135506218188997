import React from 'react';
import { Redirect, RedirectProps } from 'react-router-dom';
import { StreamBuilder, ConnectionState as State } from 'react-stream-builder';

import { Observable } from 'rxjs';
import { concatMapTo, map } from 'rxjs/operators';
import { isNil } from 'lodash';

import authSvc from '../core/auth.service';

// Guard functions

export const authGuard = (): Observable<boolean> => {
  return authSvc.stable$.pipe(
    concatMapTo(authSvc.tokens$),
    map(tokens => !isNil(tokens)),
  );
};

// Guard HoCs

export const withGuard = (
  guard: () => Observable<boolean>,
  rProps: RedirectProps,
) => (Component: any) => (props: any) => {
  return (
    <StreamBuilder
      stream={guard()}
      builder={snapshot => {
        const result = snapshot.data;
        const loading = snapshot.state !== State.active;

        if (loading) return null;
        if (result) return <Component {...props} />;
        return <Redirect {...rProps} />;
      }}
    />
  );
};

export const withAuthGuard = withGuard(authGuard, { to: '/auth ' });
