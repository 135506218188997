import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import domainSvc from '../core/domain.service';
import { catchNotify } from '../core/helpers';
import { Domain } from '../core/types';

interface Props extends RouteComponentProps<{ id: string }> {}

const DomainShow = (props: Props) => {
  const [domain, setDomain] = useState<Domain>();
  const [loading, setLoading] = useState(true);

  const id = +props.match.params.id;
  const empty = !loading && !domain;
  const show = !loading && !empty;

  useEffect(() => {
    async function fetchDomain() {
      try {
        const dom = await domainSvc.getOne(id);
        setDomain(dom);
      } catch (e) {
        catchNotify(e);
      } finally {
        setLoading(false);
      }
    }

    fetchDomain();
  }, [id]);

  return (
    <div>
      <h1>Domain Details</h1>

      {loading && <div>Loading...</div>}

      {empty && <div>The domain was not found</div>}

      {domain && show && (
        <div>
          <p>Name: {domain.name}</p>
          <p>Status: {domain.approved ? 'approved' : 'requested'}</p>
        </div>
      )}

      <Link to="/domains">Back to List</Link>
    </div>
  );
};

export default DomainShow;
