import { domains } from './network';
import { Domain } from './types';

class DomainService {
  async getAll(): Promise<Domain[] | undefined> {
    return domains.getAll();
  }

  async getOne(id: number): Promise<Domain | undefined> {
    return domains.getOne(id);
  }

  async post(domain: Partial<Domain>): Promise<Domain> {
    return domains.post(domain);
  }
}

export default new DomainService();
