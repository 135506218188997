import React from 'react';
import { StreamBuilder, ConnectionState as State } from 'react-stream-builder';
import { makeStyles } from '@material-ui/styles';
import { capitalize } from 'lodash';

import notSvc from '../core/notification.service';

const useStyles = makeStyles({
  root: {
    margin: 10,
    padding: 10,

    backgroundColor: 'lightpink',
    border: '1px solid red',
    borderRadius: 10,
  },
});

const Notifications = () => {
  const classes = useStyles();

  return (
    <StreamBuilder
      stream={notSvc.notification$}
      builder={not => {
        if (not.state !== State.active || !not.data) return null;
        const { type, message } = not.data;

        return (
          <div className={classes.root}>
            {capitalize(type)}: {message}
          </div>
        );
      }}
    />
  );
};

export default Notifications;
