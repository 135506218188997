import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { isEmpty, isNil, map } from 'lodash';

import domainSvc from '../core/domain.service';
import { catchNotify } from '../core/helpers';

const DomainIndex = (props: RouteComponentProps) => {
  const [domains, setDomains] = useState();

  const loading = isNil(domains);
  const empty = !isNil(domains) && isEmpty(domains);
  const pushDomainNew = () => props.history.push('/domains/new');

  useEffect(() => {
    async function getDomains() {
      try {
        const ds = await domainSvc.getAll();
        setDomains(ds);
      } catch (e) {
        catchNotify(e);
      }
    }

    getDomains();
  }, []);

  return (
    <div>
      <h1>Domains</h1>

      <div>
        <button type="button" onClick={pushDomainNew}>
          Request Domain
        </button>
      </div>

      {loading && <div>Loading...</div>}

      {empty && <div>You do not have linked domains</div>}

      {!empty && (
        <div>
          {map(domains, (domain, i) => (
            <li key={i}>
              {domain.name} | {domain.status} |{' '}
              <Link to={`/domains/${domain.id}`}>Details</Link>
            </li>
          ))}
        </div>
      )}
    </div>
  );
};

export default DomainIndex;
