import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Formik,
  FormikActions,
  FormikProps,
  Form,
  Field,
  FieldProps,
} from 'formik';

import authSvc from '../core/auth.service';
import { catchNotify } from '../core/helpers';

interface FormValues {
  email: string;
  password: string;
}
type FormActions = FormikActions<FormValues>;
type FormProps = FormikProps<FormValues>;
type FormFieldProps = FieldProps<FormValues>;

const Auth = () => {
  const [signed, setSigned] = useState(!!authSvc.user$.value);

  if (signed) {
    return <Redirect to="/" />;
  }

  const onSubmit = async (values: FormValues, actions: FormActions) => {
    try {
      await authSvc.signIn(values);
      setSigned(true);
    } catch (e) {
      catchNotify(e);
    }
  };

  return (
    <div>
      <h1>Auth</h1>

      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={onSubmit}
        render={(formProps: FormProps) => (
          <Form>
            {/* Email */}
            <Field
              name="email"
              render={({ field, form }: FormFieldProps) => (
                <div>
                  <input type="text" {...field} placeholder="Email" />
                  {form.touched.email && form.errors.email && (
                    <div>{form.errors.email}</div>
                  )}
                </div>
              )}
            />

            {/* Password */}
            <Field
              name="password"
              render={({ field, form }: FormFieldProps) => (
                <div>
                  <input type="text" {...field} placeholder="Password" />
                  {form.touched.password && form.errors.password && (
                    <div>{form.errors.password}</div>
                  )}
                </div>
              )}
            />

            {/* Submit */}
            <button type="submit">Submit</button>
          </Form>
        )}
      />
    </div>
  );
};

export default Auth;
