import axios, { AxiosRequestConfig as Config } from 'axios';

import authSvc from './auth.service';

const API_IP = '94.130.179.218';
const API_URL = `http://${API_IP}:8000`;

const instance = axios.create({
  baseURL: API_URL,
});

const jwtInterceptor = (config: Config): Config => {
  config.headers['Authorization'] = authSvc.header;
  return config;
};

instance.interceptors.request.use(jwtInterceptor);

export default instance;
