import { concat, of, Subject, timer } from 'rxjs';
import { mapTo, concatMap } from 'rxjs/operators';

export interface Notification {
  message: string;
  type: 'error' | 'warning' | 'info';
}

const NOTIFICATION_TIME = 3000; // milliseconds

class NotificationService {
  private subject = new Subject<Notification>();
  notification$ = this.subject.pipe(
    concatMap(not => {
      const show = of(not);
      const hide = timer(NOTIFICATION_TIME).pipe(mapTo(undefined));
      return concat(show, hide);
    }),
  );

  notify(not: Notification) {
    this.subject.next(not);
  }
}

export default new NotificationService();
